.como-funciona-section {
    text-align: center;
  }
  
  .como-funciona-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .steps-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  
  .step-box {
    padding: 27px 35px 52px 36px;
    border-radius: 10px;
    border-bottom: 5px solid #FE8FCB;
    background: #F2F6E5;
    width: 250px;
    height: 200px;
  }

  .step-box p {
    font-size: 1rem;
  }
  
  .como-funciona {
    width: 400px; /* Defina a largura desejada */
    height: auto; /* Mantenha a proporção da imagem */
  }
  
  /* Media query para telas menores */
  @media (max-width: 768px) {
    .como-funciona {
      width: 80%; /* Ajusta a largura da logo em telas menores */
      max-width: 400px; /* Limita a largura máxima da logo */
    }
  }
  
  /* Media query para telas muito pequenas (celulares) */
  @media (max-width: 480px) {
    .como-funciona {
      width: 70%; /* Ajusta a largura da logo em telas muito pequenas */
      max-width: 300px; /* Limita a largura máxima da logo */
    }
  }