.congrats-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza horizontalmente */
    justify-content: center; /* Centraliza verticalmente */
    text-align: center; /* Centraliza o texto */
    padding: 20px;
    margin: auto; /* Centraliza o container */
    max-width: 600px; /* Define a largura máxima */
    width: 100%; /* Permite que o container ocupe 100% da largura disponível */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    border-radius: 10px; /* Bordas arredondadas */
  }
  
  .email-form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza os itens do formulário */
    width: 100%; /* Permite que o formulário ocupe 100% da largura disponível */
  }
  
  .email-input {
    padding: 10px;
    margin: 10px 0;
    width: 80%; /* Ajusta a largura do campo de input */
    max-width: 300px; /* Define a largura máxima */
    border-radius: 5px; /* Bordas arredondadas */
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #007bff; /* Cor do botão */
    border: none; /* Remove borda padrão */
    border-radius: 5px; /* Bordas arredondadas */
    cursor: pointer; /* Cursor em forma de ponteiro */
  }
  
  .submit-button:hover {
    background-color: #0056b3; /* Cor ao passar o mouse */
  }
  
  .qr-code-container {
    display: none;
  }
  