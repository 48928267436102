@import url('./variables.css');

* {
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: var(--primary-color);
    position: relative;
  }
  
  body::before {
    content: '';
    position: absolute;
    width: 400px;
    height: 300px;
    border-radius: 438px;
    background: #FF8ABC;
    filter: blur(100px);
    z-index: -1;
  }
  
  body::before {
    top: -100px;
    left: -200px;
  }
  
  input, textarea, button {
    font-family: inherit;
    background-color: var(--primary-color);
    border: 1px solid black;
  }
  
  h1, h2, h3, h4 {
    color: #FF686B;
    text-align: center;
  }
  