.terms-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centraliza verticalmente */
    padding: 20px;
    margin: auto; /* Centraliza o container */
    max-width: 48rem; /* Define a largura máxima */
    width: 100%; /* Permite que o container ocupe 100% da largura disponível */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    border-radius: 10px; /* Bordas arredondadas */
  }
