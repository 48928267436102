.success-container {
    text-align: center;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  
  .success-title {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .success-image {
    max-width: 70%;
    height: auto;
    border-radius: 10px;
    margin: 20px 0;
    position: relative;
    z-index: 3;
  }
  
  .success-message, .success-tempo {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  @keyframes fall {
    0% {
      transform: translateY(-100px) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh) rotate(var(--rotate-deg)); /* Agora o coração cai por toda a altura da página */
      opacity: 0;
    }
  }
  
  .heart {
    position: fixed; /* Use fixed para garantir que a posição seja relativa à janela de visualização */
    top: 0; /* Inicia a queda a partir do topo da página */
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-size: cover;
    animation: fall linear infinite;
    z-index: 1;
  }
    
  .heart-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Garante que não interfere com interações da imagem */
    z-index: 1; /* Os corações ficam atrás da imagem */
    overflow: hidden; /* Impede que os corações saiam do container */
  }