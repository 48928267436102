@import url('./variables.css');

.container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  border-radius: 15px;
}

.form-section {
  width: 50%;
  margin-bottom: 20px; /* Adiciona espaçamento entre o texto e os outros elementos */
  text-align: justify; /* Justifica o texto */
  border-radius: 15px;
}

.form-section h1 {
  font-size: 36px;
  color: #FF686B; /* Cor romântica, entre rosa e vermelho */
  background: linear-gradient(to right, #ff4d50, #ff77a9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.preview-section {
  width: 45%;
  color: white;
  text-align: center;
}

.preview-container {
  margin-top: 20px;
}

.mac-window {
  background-image: url(./images/bg_lines.png);
  background-repeat: no-repeat;
  background-position: center; /* Centraliza a imagem dentro do container */
  border-radius: 10px;
  padding: 20px;
  border: 1px solid black;
  position: relative;
  max-width: 350px;
  margin: 0 auto;
}

.mac-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border-radius: 10px;
  padding: 8px;
  position: relative;
  border: 1px solid black;
  background-color: var(--primary-color);
}

.mac-header .arrow {
  font-size: 16px; 
  margin: 0 5px; /* Espaçamento entre as setas */
}

.mac-header .custom-url {
  width: 260px;
  left: 73%;
  position: absolute;
  transform: translateX(-50%);
  font-size: 12px;
  text-align-last: left;
}

.mac-header .dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}

.mac-header .red {
  background-color: #ff5f57;
}

.mac-header .yellow {
  background-color: #ffbd2e;
}

.mac-header .green {
  background-color: #28c941;
}

.preview-body {
  padding: 10px;
  text-align: -webkit-center;
}

.preview-image {
  max-width: 100%; /* Faz com que a imagem ocupe no máximo 100% da largura do contêiner */
  max-height: 350px; /* Limita a altura máxima da imagem */
  object-fit: cover; /* Faz com que a imagem mantenha a proporção, cortando a parte excedente */
  border-radius: 5px; /* Adiciona borda arredondada */
}

.image-placeholder {
  background-image: url(./images/image_preview.png);
  background-repeat: no-repeat;
  background-position: center; /* Centraliza a imagem dentro do container */
  background-color: black;
  height: 350px;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  border: 1px solid black;
  border-radius: 10px;
  max-width: 250px;
}

.tempo-juntos {
  font-size: 14px;
}

.divider {
  margin: 20px 0;
  border: none;
  height: 1px;
  background: black;
}

.mensagem {
  font-size: 16px;
  color: #ccc;
}

button {
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

/* Contêiner do formulário */
.form-container {
  background-color: #141320;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

/* Estilo do campo do formulário */
.form-field {
  display: flex;
  flex-direction: column; /* Coloca o label e input em coluna */
  margin-bottom: 10px; /* Espaço entre os campos */
}

/* Estilo do campo da mensagem (sem margem direita) */
.form-field.message-field,
.form-field.photo-field {
  margin-right: 0; /* Remove a margem direita especificamente */
}

/* Estilo do grupo de campos */
.form-field.group {
  display: flex; /* Exibe em linha */
  justify-content: space-between; /* Espaça os campos */
}

/* Estilo de cada campo */
.field {
  flex: 1; /* Faz com que cada campo ocupe o mesmo espaço */
  margin-right: 10px; /* Espaçamento entre os campos */
}

/* Remove a margem direita do último campo */
.field:last-child {
  margin-right: 0; /* Remove margem direita do último campo */
}

/* Estilo dos inputs */
input,
textarea {
  padding: 10px;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

/* Efeito de foco nos inputs */
input:focus,
textarea:focus {
  border-color: #FF686B;
  outline: none;
}

/* Estilo do textarea */
textarea {
  height: 150px; /* Altura maior para o campo de mensagem */
  resize: vertical; /* Permite redimensionar verticalmente */
}

/* Estilo da mensagem de erro */
.error-message {
  color: #FF686B;
  font-size: 12px;
  margin-top: -10px;
}

/* Estilo do botão */
.criar-button {
  margin-top: 10px;
  background-color: black;
  color: red;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  max-width: 350px;
  padding: 20px;
}

/* Estilo para a linha de campos do formulário */
.form-row {
  display: flex;
}

.form-row-plano {
  display: flex;
}

/* Estilo do campo do formulário */
.form-field {
  flex: 1; /* Faz cada campo ocupar uma proporção igual do espaço disponível */
  margin-right: 10px; /* Espaçamento entre os campos */
}

/* Remover margem do último campo para evitar espaçamento extra */
.form-field:last-child {
  margin-right: 0; /* Remove margem do último campo */
}

.plano-selection {
  display: flex;
  flex-direction: row; /* Mude para linha */
  justify-content: space-between; /* Espalha os botões */
}

.plano-button {
  font-size: 14px;
  flex: 1; /* Faz com que cada botão ocupe espaço igual */
  cursor: pointer; /* Cursor de ponteiro ao passar o mouse */
  padding: 10px; /* Espaçamento interno */
  border-radius: 5px; /* Borda arredondada */
  text-align: center; /* Centraliza o texto */
  transition: background-color 0.3s ease; /* Transição suave para o hover */
  border: 1px solid black;
}

.plano-button:hover {
  background-color: pink; /* Cor de fundo ao passar o mouse */
}

.plano-button.selected {
  background: var(--rose);
  color: white;
}

.form-section button:disabled {
  background-color: lightgrey; /* Cor do botão quando desabilitado */
  cursor: not-allowed; /* Mudança do cursor quando desabilitado */
}

.custom-file-upload {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid black;
}

.custom-file-upload:hover {
  background-color: pink; /* Cor ao passar o mouse */
}

.custom-file-upload i {
  margin-right: 5px; /* Espaço entre o ícone e o texto */
}

/* Media query para telas menores */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .form-section, 
  .preview-section {
    width: 100%;
    margin-bottom: 20px;
  }

  .preview-container {
    max-width: 100%;
  }

  .mac-window {
    max-width: 100%;
  }

  .form-row {
    flex-direction: column;
  }

  .form-field {
    width: 100%;
    margin-right: 0;
  }

  .plano-selection {
    flex-direction: column;
    align-items: center;
  }

  .plano-button {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  button {
    width: 100%;
  }
}

/* Media query para telas muito pequenas (celulares) */
@media (max-width: 480px) {
  h1, h2, p {
    font-size: 16px;
  }

  .preview-image {
    max-height: 200px;
  }

  .form-field label {
    font-size: 14px;
  }

  input, 
  textarea {
    font-size: 14px;
    padding: 8px;
  }

  button {
    font-size: 14px;
    padding: 8px !important;
  }
}

.footer {
  padding: 20px;
  text-align: center;
  border-radius: 0 0 15px 15px;
  background-image: 
    linear-gradient(to bottom, rgba(245, 235, 224, 1), rgba(245, 235, 224, 0)), /* Degradê no topo */
    url(./images/bg_squares.png);
  background-repeat: round;
}

.footer-links {
  display: flex;
  gap: 20px;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

.footer-link {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #e91e63;
}
@font-face {
  font-family: 'Mighttel';
  src: url('./fonts/Mighttel.ttf') format('truetype'); /* Ajuste o caminho conforme necessário */
}

.cursive {
  font-family: 'Mighttel', cursive;
  -webkit-text-stroke: 1px black; /* Mantém o contorno preto */
  color: #FF89C7; /* Rosa da fonte */
  text-shadow: -3px 0 0 rgba(255, 0, 0, 1); /* Sombra vermelha mais suave com blur */
  font-size: 4rem;
  font-weight: normal;
}

p, label, i {
  color: black;
}

label {
  margin-bottom: 8px;
}

.App-header {
  display: flex; /* Habilita o Flexbox */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente, se necessário */
}

.logo {
  width: 800px; /* Defina a largura desejada */
  height: auto; /* Mantenha a proporção da imagem */
}

/* Media query para telas menores */
@media (max-width: 768px) {
  .logo {
    width: 80%; /* Ajusta a largura da logo em telas menores */
    max-width: 400px; /* Limita a largura máxima da logo */
  }
}

/* Media query para telas muito pequenas (celulares) */
@media (max-width: 480px) {
  .logo {
    width: 70%; /* Ajusta a largura da logo em telas muito pequenas */
    max-width: 300px; /* Limita a largura máxima da logo */
  }

  .mac-header .custom-url {
    width: 150px !important;
    left: 60%;
  }
}